export const API_URL = process.env.VUE_APP_API_URL || '//media.lndata.pro' as string
export const API_MEDIA = process.env.VUE_APP_API_MEDIA || '//media.lndata.pro/media/' as string
export const ADV_LINK = process.env.VUE_APP_ADV_LINK || 'https://yandex.ru/' as string
export const ADV_IMAGE = process.env.VUE_APP_ADV_IMAGE || 'IMG_8994.png' as string
export const SOC_TIKTOK = process.env.VUE_APP_SOC_TIKTOK || 'https://yandex.ru/' as string
export const SOC_VK = process.env.VUE_APP_SOC_VK || 'https://yandex.ru/' as string
export const SOC_TG = process.env.VUE_APP_SOC_TG || 'https://yandex.ru/' as string
export const SOC_NUUM = process.env.VUE_APP_SOC_NUUM || 'https://yandex.ru/' as string
export const SOC_LIKE = process.env.VUE_APP_SOC_LIKE || 'https://yandex.ru/' as string
export const SOC_YOUTUBE = process.env.VUE_APP_SOC_YOUTUBE || 'https://yandex.ru/' as string
export const METRIKA_TARGET_PLAY_BTN = process.env.VUE_APP_METRIKA_TARGET_PLAY_BTN || 'target' as string
export const METRIKA_TARGET_SERIES_BTN = process.env.VUE_APP_METRIKA_TARGET_SERIES_BTN || 'target' as string
