<script setup lang="ts">

import router from '@/router'
import BackLine from '@/components/Lines/BackLine.vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import BaseButton from '@/components/Buttons/BaseButton.vue'
import IconBase from '@/components/Icons/IconBase.vue'

import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import SocLinks from '@/components/SocLinks.vue'

const authStore = useAuthStore()
const userStore = useUserStore()

const { user: userProfile } = storeToRefs(userStore)
const logout = () => {
  authStore.logout()
}
</script>

<template>
  <BackLine>
    <CircleButton @click="router.go(-1)" name="chevron-left-gradient" bg="empty" :iconWidth="21" :iconHeight="16" view-box="0 0 21 16"/>
  </BackLine>
  <div class="profile px-10 my-20">
    <div class="profile__wrapper text-center pa-40">
      <h2 class="mb-10">{{ userProfile?.name || '' }}</h2>
      <h5 class="text-gradient text-center mb-20 d-inline-flex">Накопленные баллы: 0</h5>
      <div class="profile-actions">
        <BaseButton class="large rounded margin-auto mb-20 full-width" bg="none" border="default">Изменить профиль</BaseButton>
        <router-link :to="{name: 'profile-interface'}"><BaseButton class="large rounded margin-auto mb-20 full-width" bg="gradient" border="gradient">Изменить интерфейс</BaseButton></router-link>
        <BaseButton @click="router.go(-1)" class="large rounded margin-auto mb-20 full-width" bg="none" border="default">Назад</BaseButton>
        <BaseButton @click="logout" class="margin-auto mx-40" bg="none" border="none" color="gradient">Выйти</BaseButton>
      </div>
      <h3 class="d-inline-flex mt-40">Будь в курсе новостей!</h3>
      <a href="https://t.me/kvik_films" target="_blank">
        <BaseButton class="large rounded margin-auto my-20 full-width" bg="none" border="default">Подписаться в Телеграм</BaseButton>
      </a>

      <div v-if="false" class="profile-votes mt-40">
        <h3 class="votes-title mb-10">Твои идеи в голосовании</h3>
        <div class="votes-vote mb-40">
          <h4 class="vote-title text-gradient text-center mb-20 d-inline-flex">Мемасик</h4>
          <BaseButton class="large rounded full-width" bg="none" border="gradient">
            <p class="text">
              Посетить Японию <span class="text-gradient">20%</span>
            </p>
            <icon-base name="upload" view-box="0 0 16 18" :width="16" :height="18"/>
          </BaseButton>
        </div>
        <div class="votes-vote mb-40">
          <h4 class="vote-title text-gradient text-center mb-20 d-inline-flex">Прекрасный сад</h4>
          <BaseButton class="large rounded full-width" bg="none" border="gradient">
            <p class="text">
              Прыгнуть с парашютом <span class="text-gradient">54%</span>
            </p>
            <icon-base name="upload" view-box="0 0 16 18" :width="16" :height="18"/>
          </BaseButton>
        </div>
      </div>
    </div>
    <SocLinks />
  </div>
</template>

<style scoped lang="less">
.btn {
  .text {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  span {
    margin-right: 10px;
  }
}
</style>
