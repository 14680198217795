import { createApp } from 'vue'
import router from './router'
import Vue3TouchEvents from 'vue3-touch-events'
import { Config, initYandexMetrika } from 'yandex-metrika-vue3'
import App from './App.vue'
import './registerServiceWorker'

import '@/assets/styles/main.less'
import { createPinia } from 'pinia'

const pinia = createPinia()

const app = createApp(App)
app.use(router)
app.use(Vue3TouchEvents)
app.use(pinia)
app.mount('#app')

// eslint-disable-next-line
initYandexMetrika(app, {
  id: '98858278', router: router, env: process.env.VUE_APP_NODE_ENV, debug: true, options: { webvisor: true, trackHash: true }
} as Config)
