<script setup lang="ts">
import { ADV_IMAGE, ADV_LINK } from '@/helpers/globalVars'

const clickLink = () => {
  window.open(ADV_LINK, '_blank')
}
</script>
<template>
<img
  @click="clickLink"
  class="wrapper"
  :src="`/img/${ADV_IMAGE}`"
  :alt="ADV_IMAGE"/>
</template>

<style scoped lang="less">
.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-family: Unbounded;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

</style>
