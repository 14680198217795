<script setup lang="ts">
import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseButton from '@/components/Buttons/BaseButton.vue'
import BackLine from '@/components/Lines/BackLine.vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import { useAuthStore } from '@/stores/auth'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import SocLinks from '@/components/SocLinks.vue'

const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()
const errors = ref<any>({})
const isLoading = ref<boolean>(false)
const username = ref<string>('')
const password = ref<string>('')
const isAuth = computed(() => authStore.isAuth)

const inFocus = () => {
  errors.value = {}
}

const setLoading = (status: boolean) => {
  isLoading.value = status
}
const onSubmit = async () => {
  setLoading(true)
  const result = await authStore
    .login({
      username: username.value,
      password: password.value
    }).finally(() => setLoading(false))

  if (result.error) {
    errors.value.detail = 'Неверный логин или пароль.'
  }
  authStore.setAuth(result)
  if (isAuth.value) {
    await router.push(route.redirectedFrom?.path || '/login/success')
  }
}

</script>

<template>
  <BackLine>
    <CircleButton @click="router.go(-1)" name="chevron-left-gradient" bg="empty" :iconWidth="21" :iconHeight="16" view-box="0 0 21 16"/>
  </BackLine>
  <div class="login-account px-10 mb-20">
    <div class="login-account__wrapper text-center pa-40">
      <h2 class="mb-10">Вход</h2>
      <h5 class="text-gradient text-center mb-20 d-inline-flex">Авторизация</h5>
      <form class="create-account__form" @submit.prevent="onSubmit" enctype="multipart/form-data">
        <h5 v-if="errors?.detail" class="text-gradient text-center mb-20 d-inline-flex">{{ errors?.detail }}</h5>
        <BaseInput @focusin="inFocus" v-model="username" type="text" class="mb-10 full-width" placeholder="Ник / Почта"/>
        <BaseInput @focusin="inFocus" v-model="password" type="password" class="mb-10 full-width" placeholder="Пароль"/>
        <BaseButton :disabled="isLoading || !!Object.keys(errors).length" class="large rounded margin-auto mb-20 full-width" bg="gradient" border="gradient">Войти</BaseButton>
      </form>
      <div class="text-gradient d-inline-flex flex-direction-column">
        <br>
        <h5><span>Забыли пароль?</span></h5>
        <h5 class="text-center mb-10"><router-link :to="{name: 'login-restore'}">Восстановить</router-link></h5>
        <br>
        <h5><span>Еще нет аккаунта?</span></h5>
        <h5><router-link :to="{name: 'login-register'}">
          <BaseButton class="large rounded" bg="none" border="gradient" color="gradient">Зарегистрироваться</BaseButton>
        </router-link></h5>
      </div>
    </div>
    <SocLinks />
  </div>
</template>

<style scoped lang="less">
.login-account {
  &__form {
    display: flex;
    flex-direction: column;
  }
  &__wrapper {
    position: relative;
    border: none;
    .text-gradient {
      h6, h5 {
        background:var(--gradient-1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        span {
          background: none;
          -webkit-text-fill-color: #fff;
        }
      }
    }
  }
}
</style>
